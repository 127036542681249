import React from 'react';
import { useDrop } from 'react-dnd';
import {ItemTypes} from '@mui/material';

function DropZone({ date, index, moveJob, assignCrewToJob, children }) {
    const ItemTypes = {
        JOB: 'JOB',
        CREW_LIST: 'CREW_LIST',
      };
  const [{ isOver }, dropRef] = useDrop({
    accept: [ItemTypes.JOB],
    drop: (item, monitor) => {
      if (!item) return;

      if (item.type === ItemTypes.JOB) {
        // Reorder or move the job
        const dragDate = item.date;
        const dragIndex = item.index;
        const dropDate = date.format('YYYY-MM-DD');
        const dropIndex = index;

        // If it’s exactly the same spot, do nothing
        if (dragDate === dropDate && dragIndex === dropIndex) return;

        moveJob(dragDate, dragIndex, dropDate, dropIndex);

        // Update the item so future drops know about new index
        item.index = dropIndex;
        item.date = dropDate;
      } else if (item.type === ItemTypes.CREW_LIST) {
        //dont accept crew list
        return;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={dropRef}
      style={{
        minHeight: '20px',
        margin: '0px 0',
        backgroundColor: isOver ? 'lightblue' : 'transparent',
        transition: 'background-color 0.2s ease',
      }}
    >
      {children}
    </div>
  );
}

export default DropZone;
