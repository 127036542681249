// ScheduleColumn.js
import React, { useState, useEffect, useRef } from 'react';
import './MatchSchedule.css';
import TextField from '@mui/material/TextField';
import { useDrag, useDrop } from 'react-dnd';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { CalendarIcon } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropZone from './DropZone';
import AlarmIcon from '@mui/icons-material/Alarm';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { EventNote } from '@mui/icons-material';
import ScheduleColumnSummary from './ScheduleColumnSummary';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
const ItemTypes = {
  JOB: 'JOB',
  CREW_LIST: 'CREW_LIST',
};

// DroppableJob Component
function DroppableJob({ job, index, date, crewColors, moveJob, assignCrewToJob, employees }) {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.JOB, ItemTypes.CREW_LIST],
    hover: (item, monitor) => {
      // OPTIONAL: If you want live “highlighting” or any hover-based logic, do it here.
      // Just DON’T call moveJob here if you only want a final drop-based reorder.
    },
    drop: (item, monitor) => {
      if (!ref.current || !item) return;

      if (item.type === ItemTypes.JOB) {
        const dragDate = item.date;
        const dragIndex = item.index;
        const dropDate = date.format('YYYY-MM-DD');
        const dropIndex = index;

        // Only reorder if it's a different spot
        if (dragDate === dropDate && dragIndex === dropIndex) return;

        // Finalize the move on drop
        moveJob(dragDate, dragIndex, dropDate, dropIndex);

        // Update item to reflect new index/date
        item.index = dropIndex;
        item.date = dropDate;
      }
      else if (item.type === ItemTypes.CREW_LIST) {
        const crewIndex = item.index;
        assignCrewToJob(date.format('YYYY-MM-DD'), index, crewIndex);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });
  const handleWipLinkClick = async () => {
    const path = '/wip?lot_oid=' + job.lot_oid;
    window.open(path, '_blank');
  };
  
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.JOB,
    item: {
      id: job.id,
      index,
      date: date.format('YYYY-MM-DD'),
      type: ItemTypes.JOB
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.5 : 1;

  // Get employee names
  const employeeNames = employees
    .filter(emp => job.employee_oids && job.employee_oids.includes(emp.oid))
    .map(emp => emp.name);

  return (
    <div
      ref={ref}
      className="droppable-job"
      style={{
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        opacity,
        backgroundColor: '#f0f0f0',
        transition: 'background-color 0.2s ease, opacity 0.2s ease',
        marginBottom: '0px',
        cursor: 'move',
      }}
    >

      <div style={{ position: 'relative' }}>
        {/* Upper Row: Phase Type on the Right and Index on the Left */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px' }}>
          <span style={{ fontSize: '11px' }}>
            {index + 1}
            <IconButton
              className="recent-lots-button"
              color="primary"
              aria-label="save"
              size="small"
              onClick={handleWipLinkClick}
              style={{ padding: '4px' }} // Optional: Adjust the button padding for a smaller look
            >
              <FontAwesomeIcon
                icon={faEye}
                style={{ fontSize: '11px' }} // Adjust the icon size directly
              />
            </IconButton>

          </span>
          <Chip style={{
            fontSize: '11px',
            padding: '2px 4px', // Adjust padding to reduce space
            minWidth: '0',      // Remove minWidth if necessary to fit text tightly
          }} label={job.phase_type} />
        </div>

        {/* Builder, Neighborhood, and Lot Number (stacked and aligned to the left) */}
        <div style={{
          borderTop: '2px solid #ccc', fontSize: '11px', textAlign: 'left', display: 'flex',          // Use flex to align the items in one line
          flexWrap: 'wrap', gap: '5px',
        }}>
          <div>{job.builder} |</div>
          <div>{job.neighborhood} |</div>
          <div>{job.lot_num}</div>
        </div>

      </div>

      {employeeNames.length > 0 && (
        <div style={{ border: '2px solid #ccc', backgroundColor: job.crewIndex !== undefined ? crewColors[job.crewIndex % crewColors.length] : '#f0f0f0', marginTop: '5px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>

          {employeeNames.map((name, idx) => (
            <Chip style={{
              fontSize: '11px',
              padding: '5px 4px',
              marginTop: '5px', // Adjust padding to reduce space
              minWidth: '0',      // Remove minWidth if necessary to fit text tightly
            }} key={idx} label={name} size="small" />
          ))}
        </div>
      )}
    </div>
  );
}

// ScheduleColumn Component
function ScheduleColumn({ columnIndex, date, crewColors, crews, assignCrewToJob, moveJob, jobs, isPastDue, onDateChange, employees, isCollapsed, onToggleCollapse }) {
  const [selectedDate, setSelectedDate] = useState(date);

  // Notify MatchSchedule of the initial date
  useEffect(() => {
    console.log('on date change...');
    console.log(columnIndex + ',' + selectedDate);
    setSelectedDate(selectedDate);
    // onDateChange(columnIndex, selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onDateChange(columnIndex, selectedDate);
  }, [selectedDate, onDateChange])
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    //console.log('date change : ' + newDate);
    //onDateChange(columnIndex, newDate);
  };

  return (
    <div className={"match-schedule-column schedule " + (isCollapsed ? "collapsed" : "")}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton onClick={onToggleCollapse} size="small">
          {
            isCollapsed
              ? isPastDue
                ? <WarningIcon />
                : <CalendarIcon />
              : <ExpandLess />
          }
        </IconButton>
        {!isPastDue && (
          <span className='title'>{selectedDate.format('ddd')} {selectedDate.format('MM-DD')}</span>
        )}
        {isPastDue && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='title' style={{ marginLeft: '15px', textDecoration: 'underline' }}>
              Past Due
            </span>
          </div>
        )}
      </div>
      {!isCollapsed && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {!isPastDue && (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} style={{ marginBottom: '20px' }} />
                )}
              />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <IconButton onClick={() => setSelectedDate(dayjs(selectedDate).add(1, 'day'))} size="small" style={{ marginLeft: '10px' }}>
                  <AddIcon />
                </IconButton>
                <IconButton onClick={() => setSelectedDate(dayjs(selectedDate).subtract(1, 'day'))} size="small" style={{ marginLeft: '10px' }}>
                  <RemoveIcon />
                </IconButton>
              </div>
            </div>
          )}

          <ScheduleColumnSummary total={jobs.length} totalComplete={jobs.filter(job => job.date_scheduled != null).length} ></ScheduleColumnSummary>
          {jobs.length === 0 ? (
            <DropZone
              date={selectedDate}
              index={0}
              moveJob={moveJob}
              assignCrewToJob={assignCrewToJob}
            />
          ) : (
            jobs.map((job, index) => (
              <React.Fragment key={job.id}>
                <DropZone
                  date={selectedDate}
                  index={index}
                  moveJob={moveJob}
                  assignCrewToJob={assignCrewToJob}
                />
                <DroppableJob
                  job={job}
                  index={index}
                  date={selectedDate}
                  crewColors={crewColors}
                  moveJob={moveJob}
                  assignCrewToJob={assignCrewToJob}
                  employees={employees}
                />
              </React.Fragment>
            ))
          )}
          {jobs.length > 0 && (
            <DropZone
              date={selectedDate}
              index={jobs.length}
              moveJob={moveJob}
              assignCrewToJob={assignCrewToJob}
            />
          )}
        </LocalizationProvider>
      )}
    </div>
  );
}
export default ScheduleColumn;
