import React from 'react';
import { Chip, IconButton, LinearProgress, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, grey } from '@mui/material/colors';

function ScheduleColumnSummary({ total, totalComplete }) {
  const unscheduled = total - totalComplete;
  const progress = (totalComplete / total) * 100;

  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      padding: '5px 10px', 
      border:'black solid thin',
      margin:'0px',
      borderRadius: '8px', 
      height: '40px', 
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      fontSize: '14px',
    }}>
      {/* Left: Total and Unscheduled */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" style={{ marginRight: '10px', fontWeight: 'bold' }}>
        {totalComplete}/ {total}
        </Typography>
        {/* <Typography variant="body2" style={{ marginRight: '10px' }}>
          Rem: {unscheduled}
        </Typography> */}
      </div>

      {/* Center: Progress bar */}
      <div style={{ flex: 1, margin: '0 10px' }}>
        {totalComplete < total ? (
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ height: '5px', borderRadius: '5px' }}
          />
        ) : null}
      </div>

      {totalComplete === total ? (
        <IconButton size="small" disabled>
          <CheckCircleIcon style={{ color: 'green' }} />
        </IconButton>
      ) : null}

    </div>
  );
}

export default ScheduleColumnSummary;
